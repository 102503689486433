<template>
  <div>
    <b-row>
      <add-resource
        resource-name="notifications"
        add-route-name="add-notifications"
      />
    </b-row>
    <b-row align-h="center">
      <div
        v-if="loading"
        class="loader"
      />
    </b-row>
    <b-row align-h="center">
      <b-col>
        <b-card>
          <b-table
            :items="getNotifications"
            :fields="tableColumns"
          >
            <template #cell(is_sent)="{item}">
              {{ item.is_sent ? 'Yes' : 'No' }}
            </template>
            <template #cell(actions)>
              <b-dropdown
                variant="link"
                no-caret
              >
                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="align-middle text-body"
                  />
                </template>
                <b-dropdown-item>
                  <feather-icon icon="TrashIcon" />
                  <span
                    class="align-middle ml-50"
                  >Delete</span>
                </b-dropdown-item>
              </b-dropdown>
            </template>
          </b-table>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import AddResource from '@/common/components/common/Table/AddResource.vue'

export default {
  name: 'NotificationsList',
  components: {
    AddResource,
  },
  data() {
    return {
      tableColumns: [
        { key: 'title' },
        { key: 'body' },
        { key: 'sends_at', label: 'Sends At' },
        { key: 'is_sent', label: 'Sent' },
        { key: 'actions' },
      ],
      loading: true,
    }
  },
  methods: {
    getNotifications() {
      const promise = this.$notification.get('v1/admin-notification-list')

      return promise.then(res => {
        const { notifications } = res.data.data

        return notifications || []
      }).finally(() => {
        this.loading = false
      })
    },
  },
}
</script>
<style lang="scss">

</style>
